import React from 'react';
import MenuList from './MenuList';
import MenuElement from './elements/MenuElement';
import Profile from './elements/Profile';
import Help from '../../../../assets/images/sidebarIcons/help.svg';
import RoutesPaths from '../../../../RoutesPaths';
import MobileMenuIcon from '../../../Global/MobileMenuIcon';

const Sidebar = ({ setDisplayMenu, isActiveMenu }) => {
  return (
    <div className={`sidebar-container ${isActiveMenu ? 'active' : ''}`}>
      <nav>
        <div className='close-button-container'>
          <MobileMenuIcon
            setDisplayMenu={setDisplayMenu}
            isActiveMenu={isActiveMenu}
          />
        </div>
        <ul className='sidebar-nav-list'>
          {MenuList.map((element, index) => {
            return (
              <MenuElement
                key={index}
                {...element}
              />
            );
          })}
          <MenuElement
            title={'Pomoc'}
            image={Help}
            url={RoutesPaths.Help.path}
          />
          <Profile />
        </ul>
      </nav>
    </div>
  );
};

export default Sidebar;
