import React from 'react';
import { ReactComponent as Close } from '../../../assets/chip_close.svg';
import { CssTextField } from '../../../scss/settings/StyledMUIComponents';
import RedButton from '../../Global/RedButton';
import { deleteAccount } from '../../../services/authentication.service';
import AuthService from '../../../services/authentication.service';

const DeleteAccount = ({ setDeleteAccount }) => {
  const [password, setPassword] = React.useState('');
  // eslint-disable-next-line no-unused-vars
  const [error, setError] = React.useState('');
  const handleDeleteAccount = async () => {
    const user = AuthService.getCurrentUser();
    await deleteAccount(user.user_id);
  };
  return (
    <div className='sub-component-container'>
      <div className='close-container'>
        <Close
          width={12}
          height={12}
          onClick={() => setDeleteAccount(false)}
        />
      </div>
      <div className='container'>
        <h1 className='sub-component-header'>Usuń konto</h1>
        <p className='sub-component-text'>
          Ta operacja jest nieodwracalna i spowoduje usunięcie WSZYSTKICH danych powiązanych z Twoim profilem z naszych systemów.
          <br /> Aby usunąć konto należy podać hasło dostępu.{' '}
        </p>
        <CssTextField
          id='outlined-required'
          label='Hasło'
          value={password}
          name='password'
          type={'password'}
          style={{ marginBottom: '30px' }}
          onChange={(e) => setPassword(e.target.value)}
          error={error}
          hasValue={password.length > 0}
          inputProps={{
            autoComplete: 'new-password',
          }}
        />
        <RedButton
          noMargin
          handleClick={handleDeleteAccount}
        >
          Usuń moje konto
        </RedButton>
      </div>
    </div>
  );
};

export default DeleteAccount;
