import { styled } from '@mui/material/styles';
import { InputLabel, Select, TextField } from '@mui/material';

export const CssTextField = styled(TextField, {
  shouldForwardProp: (prop) => prop !== 'hasValue' && prop !== 'error',
})(({ hasValue, fullWidth, error }) => ({
  width: fullWidth ? '100%' : '360px',
  margin: !fullWidth ? '15px 0' : '0',
  '& label.Mui-focused': {
    color: hasValue ? '#333033' : '#ACACAC',
  },
  '& .MuiInput-underline:after': {
    borderBottomColor: '#333033',
  },
  '& .MuiFormLabel-root': {
    color: hasValue ? '#333033' : '#ACACAC',
    lineHeight: '26px',
    top: '-4px',
  },
  '&::placeholder': {
    color: 'red',
  },
  '& .MuiOutlinedInput-root': {
    '& fieldset': {
      borderColor: error ? 'red' : hasValue ? '#333033' : '#555555',
    },
    '&:hover fieldset': {
      borderColor: error ? 'red' : '#333033',
    },
    '&.Mui-focused fieldset': {
      borderColor: error ? 'red' : '#333033',
    },
    '&.Mui-completed fieldset': {
      borderColor: '#333033',
    },
  },
  '& input[type=number]': {
    '-moz-appearance': 'textfield',
  },
  '& input[type=number]::-webkit-outer-spin-button': {
    '-webkit-appearance': 'none',
    margin: 0,
  },
  '& input[type=number]::-webkit-inner-spin-button': {
    '-webkit-appearance': 'none',
    margin: 0,
  },
  '@media (max-width: $breakpoint-md)': {
    '& .MuiFormLabel-root': {
      lineHeight: '19px', // Zmieniamy line-height na 19px
      fontSize: '16px',
    },
  },
}));

export const CssSelect = styled(Select, {
  shouldForwardProp: (prop) => prop !== 'hasValue',
})(({ hasValue }) => ({
  '& label.Mui-focused': {
    color: hasValue ? '#333033' : '#ACACAC',
  },
  '& .MuiInput-underline:after': {
    borderBottomColor: '#333033',
  },
  '& .MuiFormLabel-root': {
    color: hasValue ? '#333033' : '#ACACAC',
  },
  '& .MuiFormLabel-root-focused': {
    color: hasValue ? '#333033' : '#ACACAC',
  },
  '& .MuiOutlinedInput-root': {
    '& fieldset': {
      borderColor: hasValue ? '#333033' : '#ACACAC',
    },
    '&:hover fieldset': {
      borderColor: '#333033',
    },
    '&.Mui-focused fieldset': {
      borderColor: '#333033',
    },
    '&.Mui-completed fieldset': {
      borderColor: '#333033',
    },
  },
}));
export const ProfileSelect = styled(Select, {
  shouldForwardProp: (prop) => prop !== 'hasValue' && prop !== 'error',
})(({ hasValue, fullWidth, error }) => ({
  width: fullWidth ? '100%' : '360px',
  margin: !fullWidth ? '15px 0' : '0',
  '& label.Mui-focused': {
    color: hasValue ? '#333033' : '#ACACAC',
  },
  '& .MuiInput-underline:after': {
    borderBottomColor: '#333033',
  },
  '& label#demo-simple-select-label': {
    color: hasValue ? '#333033' : '#ACACAC',
    fontFamily: 'Montserrat, sans-serif',
    top: 17,
  },
  '& .MuiOutlinedInput-root': {
    '& fieldset': {
      borderColor: error ? 'red' : hasValue ? '#333033' : '#555555',
    },
    '&:hover fieldset': {
      borderColor: error ? 'red' : '#333033',
    },
    '&.Mui-focused fieldset': {
      borderColor: error ? 'red' : '#333033',
    },
    '&.Mui-completed fieldset': {
      borderColor: '#333033',
    },
  },
}));

export const CssInputLabel = styled(InputLabel, {
  shouldForwardProp: (prop) => prop !== 'hasValue',
})(({ hasValue }) => ({
  '& label.Mui-focused': {
    color: hasValue ? '#333033' : '#ACACAC',
  },
  '& .MuiInput-underline:after': {
    borderBottomColor: '#333033',
  },
  '& .MuiFormLabel-root': {
    color: hasValue ? '#333033' : '#ACACAC',
  },
  '& .MuiOutlinedInput-root': {
    '& fieldset': {
      borderColor: hasValue ? '#333033' : '#ACACAC',
    },
    '&:hover fieldset': {
      borderColor: '#333033',
    },
    '&.Mui-focused fieldset': {
      borderColor: '#333033',
    },
    '&.Mui-completed fieldset': {
      borderColor: '#333033',
    },
  },
}));
