import React from 'react';
import PopoverButton from '../../Global/PopoverButton';
const Row = ({ invoice, index }) => {
  const handlePopoverButton = () => {
    console.log('Pobierz fakturę');
  };
  return (
    <tr>
      <th className='index'>{index}.</th>
      <th className='date'>{new Date(invoice.created_at).toLocaleDateString()}</th>
      <th className='invoice'>f/1346/2445/3</th>
      <th className='amount'>{invoice.amount}zł</th>
      <th className='status'>{invoice.payment_status}</th>
      <th className='download'>
        <PopoverButton
          text={'Pobierz fakturę'}
          handlePopoverButton={handlePopoverButton}
        />
      </th>
    </tr>
  );
};

export default Row;
