import React from 'react';
import { Grid } from '@mui/material';
import integrationService from '../../../services/integrations.service';
import PopoverButton from '../../Global/PopoverButton';
const APIKey = ({ setRefetch, uuid, id, key_name, created_at, due_to }) => {
  const handleDeleteAPI = () => {
    integrationService
      .deleteKey(uuid)
      .then((r) => {
        if (r.ok) {
          setRefetch((prevState) => !prevState);
        } else {
          return r.json();
        }
      })
      .then((data) => console.log(data));
  };
  return (
    <Grid
      container
      style={{ padding: '20px 10px', borderBottom: '0.5px solid #DBDBDB' }}
    >
      <Grid
        item
        xs={0.3}
        md={0.3}
      >
        <span className='table-item'>{id}.</span>
      </Grid>
      <Grid
        item
        xs={2}
        md={2}
      >
        <span className='table-item'> {key_name}</span>
      </Grid>
      <Grid
        item
        xs={4}
        md={2}
      >
        <span className='table-item'>
          {created_at
            ? `${new Date(created_at).getDate().toString()}${'-'}${(new Date(created_at).getMonth() + 1).toString()}${'-'}${new Date(created_at)
                .getFullYear()
                .toString()}`
            : '-'}
        </span>
      </Grid>
      <Grid
        item
        xs={5.4}
        md={7.4}
      >
        <span className='table-item'>
          {due_to
            ? `${new Date(due_to).getDate().toString()}${'-'}${(new Date(due_to).getMonth() + 1).toString()}${'-'}${new Date(due_to)
                .getFullYear()
                .toString()}`
            : '-'}
        </span>
      </Grid>
      <Grid
        item
        xs={0.3}
        md={0.3}
        style={{ position: 'relative' }}
      >
        <PopoverButton
          text={'Usuń'}
          handlePopoverButton={handleDeleteAPI}
          style={{ color: '#EA0A0A' }}
        />
      </Grid>
    </Grid>
  );
};

export default APIKey;
