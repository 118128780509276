import React from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
const MenuElement = ({ image, title, url, margin }) => {
  const navigate = useNavigate();
  const location = useLocation();

  return (
    <li
      className={
        location.pathname === url
          ? margin
            ? 'sidebar-li margin selected-background-li'
            : 'sidebar-li selected-background-li'
          : margin
            ? 'sidebar-li margin'
            : 'sidebar-li'
      }
      onClick={() => navigate(url)}
    >
      {location.pathname === url ? <div className='selected-li' /> : null}
      <img
        className={`icon ${location.pathname === url && 'active-icon'}`}
        src={image}
        alt={title}
      />
      <p className={location.pathname === url ? 'menu-element-title active-p' : 'menu-element-title'}>{title}</p>
    </li>
  );
};

export default MenuElement;
