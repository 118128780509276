import React, { useState, useEffect, useRef } from 'react';

const PopoverButton = ({ text, handlePopoverButton, style }) => {
  const [showPopover, setShowPopover] = useState(false);
  const containerRef = useRef(null);
  const handleClickOutside = (event) => {
    if (containerRef.current && !containerRef.current.contains(event.target)) {
      setShowPopover(false);
    }
  };

  useEffect(() => {
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  return (
    <div
      className='popover-button'
      ref={containerRef}
    >
      <span
        className='dots'
        onClick={() => setShowPopover(!showPopover)}
      >
        ...
      </span>
      {showPopover && (
        <button
          className='popover-action'
          onClick={handlePopoverButton}
        >
          <span style={style ? { ...style } : {}}>{text}</span>
        </button>
      )}
    </div>
  );
};

export default PopoverButton;
